<template>
  <div
    class="bg-black h-full fixed top-0 left-0 w-full lg:w-[--nav-left-width] z-10 lg:z-20 transition-transform overflow-y-scroll scrollbar-hide nav-left p-2"
    :class="[
      { '-translate-x-full lg:-translate-x-full' : ['hidden', 'mini'].includes(navState) },
      { 'mt-[76px] pb-[80px] lg:mt-0': ['full'].includes(navState) }
    ]"
  >
    <div class="flex justify-center flex-wrap flex-col items-center">
      <NavigationSectionCompetitions v-if="isUserLogged"/>
      <NavigationSectionGamesInstantWin v-if="isUserLogged"/>
      <NavigationSectionGamesLive v-if="isUserLogged"/>
      <NavigationSectionSports v-if="isUserLogged && sportsBettingProviders[0].enabled"/>
      <NavigationSectionVip v-if="isUserLogged && nuxtApp.$config.public.SHOW_VIP"/>
      <NavigationSectionPromotions v-if="isUserLogged"/>
      <NavigationSectionGames v-if="isUserLogged"/>
      <NavigationSectionCommunity v-if="isUserLogged"/>
      <NavigationSectionBridge v-if="isUserLogged"/>
      <NavigationSectionRaf v-if="isUserLogged"/>
      <NavigationSectionUser v-if="isUserLogged"/>
      <NavigationSectionSupport v-if="isUserLogged"/>
      <NavigationSectionSocials/>
      <PaymentsBanner v-if="isUserLogged" small/>
      <SwitchCompetitionValue
        id="expandedCurrencyToggle"
        class="my-2"
        small
      />
      <UserFlagSwitch small/>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';
import { useSportsBettingStore } from '@/store/sportsBetting';

const uiStore = useUiStore();
const {
  navState,
} = storeToRefs(uiStore);

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const nuxtApp = useNuxtApp();

const sportsBettingStore = useSportsBettingStore();
const { sportsBettingProviders, } = storeToRefs(sportsBettingStore);
</script>
